<template>
  <div>
    <MyHeader />
    <main id="content">
      <section class="hero-introduction-about-us">
        <div class="container-fluid">
          <div class="row align-items-center justify-content-center">
            <div class="offset-xl-1 col-xl-4 col-lg-5 col-md-12 col-sm-12 col-sm-12 order-lg-1 order-1">
              <h1 class="text-uppercase">
                Who <span>are</span> we?
              </h1>
              <p>We are a platform for short-term property rentals, with special concentration in all Latin American and Caribbean countries. Our on-site personalized service for guests and owners is what makes us unique.</p>
              <h4>Our essence is what marks the difference!</h4>
            </div>
            <div class="col-xl-7 col-lg-7 col-md-10 col-sm-8 order-lg-1 order-2">
              <figure class="m-lg-0">
                <img src="/img/hero-img-about-us.jpg"
                     alt="home be like image"
                     class="img-fluid">
              </figure>
            </div>
            <div class="offset-xl-0 col-xl-4 offset-lg-1 col-lg-5 col-md-8 negative-margin-top order-lg-1 order-4">
              <figure class="m-lg-0">
                <img src="/img/aboutUs-collage.png"
                     alt="aboutUs-collage"
                     class="img-fluid">
              </figure>
            </div>
            <div class="offset-xl-1 col-xl-4 col-lg-6 order-lg-1 order-3">
              <h2 class="text-uppercase">
                guest
              </h2>
              <p>Transform your vacation into a unique, unforgettable and cultural experience in each destination visited, making you feel as a guest and at the same time, as if you were at your own home. You will live the experience of the destination like a local, with our native Experiences Specialists based in each destination. They will accompany you throughout the journey of your trip that will start at the time of booking, continuing even when you are already at home.</p>
            </div>
            <div class="offset-xl-1 col-xl-4 offset-lg-1 col-lg-6 mt-lg-5 order-lg-1 order-5">
              <h2 class="text-uppercase">
                Owner
              </h2>
              <p>You will accumulate the greatest income from your property rental, promoting it among various commercial channels, delegating in expert hands the demanding and delicate task of taking care of this, as well as the personalized treatment of your guests. You will have the peace of mind that the standards of service, cleanliness and care of your property will be up to the highest level.</p>
            </div>
            <div class="offset-xl-1 col-lg-4 col-lg-5 col-md-8 negative-margin-top order-lg-1 order-6">
              <figure class="m-0">
                <img src="/img/owner-aboutUs-image.jpg"
                     alt="aboutUs-collage"
                     class="img-fluid">
              </figure>
            </div>
          </div>
        </div>
      </section>
      <section class="experience-home-section background-experience-about-section">
        <div class="container">
          <div class="row justify-content-end">
            <div class="offset-xl-9 pl-xl-5 col-xl-6 col-lg-7 col-md-12">
              <h2 class="section-title">
                Ready to live the experience of a lifetime?
              </h2>
              <p>HomeBeLike is the portal where you can expand your mind and wishes for what you are looking for!. Experience a trip tailored just for you. Be able to enjoy the cities you visit as one of the locals, in a pleasant, unique, effective and direct way with the best native experiences of the destination.</p>
            </div>
          </div>
        </div>
      </section>
      <section class="home-be-like-information">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="offset-xl-1 col-xl-4 pl-xl-0 pl-lg-5 col-lg-6">
              <h2 class="text-uppercase">
                How does it work?
              </h2>
              <p>Once you decide to become a part of the HomeBeLike family, a group of specialists will visit your property in order to make a detailed assessment to verify that the unit complies with the standards established by HomeBeLike. At the end of the assessment, you will receive a detailed report with relevant feedback and suggestions, and then go on to the next step.</p>
            </div>
            <div class="col-xl-7 col-lg-6 pr-xl-5 pr-lg-5">
              <figure class="m-0">
                <img src="/img/how-works-aboutUs.jpg"
                     alt="how-works-aboutUs"
                     class="img-fluid">
              </figure>
            </div>
          </div>
        </div>
      </section>
      <section class="our-promise">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <figure class="m-lg-0">
                <img src="/img/our-promise-aboutUs.jpg"
                     alt="how-works-aboutUs"
                     class="img-fluid">
              </figure>
            </div>
            <div class="pl-xl-5 col-lg-6">
              <h2 class="text-uppercase">
                Our promise!
              </h2>
              <p>Just relax! Our team will take care of every little detail on your trip High Standard Experiences Specialists with Top Personalized Service HomeBeLike has a select team of Experiences Specialists around the world. With a background in brands such as Marriott, Inspirato, SLH, Preferred & Relais & Cheaux, our Experiences Specialists will make of your stay and experiences a unique and unforgettable one.</p>
            </div>
          </div>
        </div>
      </section>
      <!-- <div class="green-belt"
          style="background-image: url('/img/img01.jpg');">
        <div class="container">
          <div class="row">
            <div class="col text-center">
              <h1 class="title mb-0 text-white">
                About Us
              </h1>
              <p class="text-white">
                Be Our Gest, Feel Like Home.
              </p>
            </div>
          </div>
        </div>
      </div>
      <br>
      <div class="container-fluid mb-5">
        <div class="row align-items-center justify-content-between">
          <div class="col-12 col-md-6">
            <div class="justify-content-end d-flex pr-xl-5">
              <div class="aboutParagraph">
                <h2>HOME <span>BE</span> LIKE <small>EXCLUSIVE VACATIONS SPECIALIST </small></h2>
                <p>Sumérjase en un  mundo de posibilidades  al rededor del mundo y permítanos  hacer de sus  vacaciones, retiros empresariales o viajes de negocios  una experiencia única. Cada uno de los integrantes de nuestro gran equipo está listo para cubrir cada detalle de su experiencia desde su reserva hasta el momento en que retorne a su lugar de origen.</p>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 pr-0">
            <img src="/img/img01.jpg"
                class="w-100">
          </div>
        </div>
      </div>
      <div class="container">
        <hr class="mb-5">
        <div class="row align-items-center justify-content-center">
          <div class="col-12 col-md-3">
            <img src="/img/calendar-circle.png"
                class="img-fluid mx-auto d-block">
            <br>
          </div>
          <div class="col-12 col-md col-lg-8">
            <h4>Personal and Tailor Made Itinerary</h4>
            <p>Nuestro agente de ventas  y nuestro Destination Specialist. Se encargaran   en el paso a paso desde su reserva  y en todo el proceso de registro y planeación de su viaje  para que no  tenga que preocuparse de ningún detalle de su viaje  y que su única preocupación sea como relajarse y disfrutar de su descanso o trabajo en cualquiera de  nuestros destinos. Como resultado de este  proceso estaremos entregando un itinerario detallado de su viaje.</p>
          </div>
        </div>
        <br>
        <div class="row align-items-center justify-content-center">
          <div class="col-12 col-md-3">
            <img src="/img/check-circle.png"
                class="img-fluid mx-auto d-block">
            <br>
          </div>
          <div class="col-12 col-md col-lg-8">
            <h4>Local And Exclusive Unique Experiences</h4>
            <p>Hemos creado con mucho detalle  y dedicación  cada experiencia en cada uno de nuestros destinos y con los mejores expertos para hacer de cada vivencia un  momento inolvidable. Contacte a su Destination Specialist para crear su propia experiencia  y déjese llevar de la magia que solo en HomeBeLike  puede vivir. </p>
          </div>
        </div>
        <br>
        <div class="row align-items-center justify-content-center">
          <div class="col-12 col-md-3">
            <img src="/img/services-circle.png"
                class="img-fluid mx-auto d-block">
            <br>
          </div>
          <div class="col-12 col-md col-lg-8">
            <h4>Costume made and luxury concierge services</h4>
            <p>HomeBeLike cuenta con un selecto equipo de especialistas con experiencia alrededor del mundo en lo que a Luxury Concierge  services  respecta.  Con trayectoria en SLH, Preferred  & Relais & Cheaux, nuestros Destination Concierge harán de su estadia y sus experiencias  algo único.</p>
          </div>
        </div>
        <hr>
      </div> -->
    </main>
    <MyFooter />
  </div>
</template>

<script>
export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'About Us',
    // all titles will be injected into this template
    // titleTemplate: '%s | Exclusive experience specialists',
    meta: [
      { name: 'description', content: 'We are a platform for short-term property rentals, with special concentration in all Latin American and Caribbean countries.' },
      // Schema.org markup for Google+
      { itemprop: 'name', content: 'About Us | CapCana Rentals' },
      { itemprop: 'description', content: 'We are a platform for short-term property rentals, with special concentration in all Latin American and Caribbean countries.' },
      { itemprop: 'image', content: 'https://homebelike.com/img/experience-aboutUs.png' },
      // Twitter Card data
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: 'About Us | CapCana Rentals' },
      { name: 'twitter:description', content: 'We are a platform for short-term property rentals, with special concentration in all Latin American and Caribbean countries.' },
      // Twitter summary card with large image must be at least 280x150px
      { name: 'twitter:image:src', content: 'https://homebelike.com/img/experience-aboutUs.png' },
      // Open Graph data
      { property: 'og:title', content: 'About Us | CapCana Rentals' },
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: 'https://homebelike.com/' },
      { property: 'og:image', content: 'https://homebelike.com/img/experience-aboutUs.png' },
      { property: 'og:description', content: 'We are a platform for short-term property rentals, with special concentration in all Latin American and Caribbean countries.' },
    ]
  },
};
</script>

<style scoped>

</style>
